import React, { useEffect } from "react";

/* COMPONENTS */
import Product from "../components/Product";
import Loader from "../components/Loader";
import Message from "../components/Message";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

/* ACTION CREATORS */
import { listProducts } from "../actions/productActions";
import { Slider } from "../components/HomePage/Slider";
import { CategoryHome } from "../components/HomePage/CategoryHome";
import { HowWorks } from "../components/HomePage/HowWorks";
import {Rating} from "../components/HomePage/Rating";
import { WhyChoose } from "../components/HomePage/WhyChoose";

function HomeScreen({ history }) {
  const dispatch = useDispatch();

  /* PULLING A PART OF STATE FROM THE ACTUAL STATE IN THE REDUX STORE */
  const productList = useSelector((state) => state.productList);
  const { products, page, pages, loading, error } = productList;

  /* FIRING OFF THE ACTION CREATORS USING DISPATCH */

  let keyword =
    history.location
      .search; /* IF USER SEARCHES FOR ANYTHING THEN THIS KEYWORD CHANGES AND USE EFFECT GETS TRIGGERED */
  
  useEffect(() => {
    dispatch(listProducts(keyword));
  }, [dispatch, keyword]);

  return (
    <>
      <Slider/>
      <CategoryHome/>
      <WhyChoose/>
      <HowWorks/>
      {/* <Product/> */}
      <section className="bg0 p-t-23 p-b-23">
      {/* {!keyword && <ProductCarousel />}  */}
        <div className="container">
            <div className="p-b-10">
              <h3 className="ltext-103 cl5 text-center mb-4">
                  Product Overview
              </h3>
            </div>
                {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message variant="danger">{error}</Message>
                  ) : (
                   <div className="container">
                      <div className="row isotope-grid">
                        {products.map((product) => {
                          return (
                              <div key={product._id} className="col-sm-6 col-md-4 col-lg-3 p-b-35 isotope-item women">
                                <div className="block2">
                                  <Product product={product} />
                                </div>
                              </div>
                            );
                        })}
                      </div>
                    </div>
                )}
        </div>
      </section>
      <Rating/>

    </>
  );
}

export default HomeScreen;
