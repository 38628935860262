import React, { useState } from "react";
import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom';

/* REACT ROUTER DOM */
import { useHistory } from "react-router-dom";

function SearchBox() {
  /* STATE */
  const [keyword, setKeyword] = useState("");

  let history =
    useHistory(); /* CAN'T DIRECTLY USE HISTORY AS IT'S NOT AN ACTUAL PAGE SO CAN'T DESTRUCTURE PROPS */

  /* HANDLER */
  const submitHandler = (e) => {
    e.preventDefault();

    // WHEN USER HITS SUBMIT, REDIRECT TO HOME PAGE TO SEE PRODUCTS AND APPEND ?keyword=...IN URL
    if (keyword) {
      history.push(`/shop/?keyword=${keyword}&page=1`);
    } else {
      // IF WE HIT SUBMIT WITHOUT KEYWORD, WE DON'T WANT THE USER TO GET REDIRECTED IN THAT CASE RATHER STAY ON WHATEVER PAGE HE WAS
      history.push(history.push(history.location.pathname));
    }
  };

  return (
    <>
          <div className="modal-search-header flex-c-m trans-04 js-hide-modal-search">
                <div className="container-search-header">
                <button className="flex-c-m btn-hide-modal-search trans-04 js-hide-modal-search">
                    <img src="static/images/icons/icon-close2.png" alt="CLOSE" />
                </button>
                <form onSubmit={submitHandler}  className="wrap-search-header flex-w p-l-15">
                    <button className="flex-c-m trans-04"  type="submit">
                      <i className="zmdi zmdi-search" />
                    </button>
                    <input className="plh3" type="text" name="q" onChange={(e) => setKeyword(e.target.value)} placeholder="Search..." />
                </form>
                </div>
            </div>
    </>

  );
}

export default SearchBox;
