import React, { useState, useEffect } from "react";

/* REACT ROUTER */
import { Link } from "react-router-dom";

/* REACT BOOTSTRAP */
import { Row, Col, ListGroup, Image, Card, Button } from "react-bootstrap";

/* PAYPAL BUTTONS */
// import { PayPalButton } from "react-paypal-button-v2";

/* COMPONENTS */
import Message from "../components/Message";
import Loader from "../components/Loader";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

/* ACTION CREATORS */
import {
  getOrderDetails,
  payOrder,
  deliverOrder,
} from "../actions/orderActions";

/* ACTION TYPES */
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
} from "../constants/orderConstants";

function OrderScreen({ history, match }) {
  const orderId = match.params.id;

  const dispatch = useDispatch();

  const [sdkReady, setSdkReady] = useState(false);

  /* PULLING A PART OF STATE FROM THE ACTUAL STATE IN THE REDUX STORE */
  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, error, loading } = orderDetails;

  const orderPay = useSelector((state) => state.orderPay);
  const { loading: loadingPay, success: successPay } = orderPay;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // ITEMS PRICE GETS CALCULATED ONLY IF WE HAVE AN ORDER
  if (!loading && !error) {
    order.itemsPrice = order.orderItems
      .reduce((acc, item) => acc + item.price * item.qty, 0)
      .toFixed(2);
  }

  // PAYPAL BUTTONS
  // const addPayPalScript = () => {
  //   const script = document.createElement("script");
  //   script.type = "text/javascript";
  //   script.src =
  //     "https://www.paypal.com/sdk/js?client-id=AYgflmsaM7ccNLPlKUiufIyw8-spOE4UuS5XyyTCvhzheA-1EUcZF9qGlgXBZaSKcP5BY0zTc9WgINKe";
  //   script.async = true;
  //   script.onload = () => {
  //     setSdkReady(true);
  //   };
  //   document.body.appendChild(script);
  // };

  useEffect(() => {
    // IS USER IS NOT LOGGED IN THEN REDIRECT TO LOGIN PAGE
    if (!userInfo) {
      history.push("/login");
    }

    // CHECK IF WE HAVE THE ORDER DETAILS, IF NOT DISPATCH AN ACTION TO GET THE ORDER DETAILS
    if (
      !order ||
      successPay ||
      order._id !== Number(orderId) ||
      successDeliver
    ) {
      dispatch({ type: ORDER_PAY_RESET });

      dispatch({ type: ORDER_DELIVER_RESET });

      dispatch(getOrderDetails(orderId));
    } 
    else if (!order.isPaid) {
      // ACTIVATING PAYPAL SCRIPTS
      if (!window.paypal) {
        console.log("Hello From PayPal")
      } else {
        setSdkReady(true);
      }
    }
  }, [dispatch, order, orderId, successPay, successDeliver, history, userInfo]);
  
  // useEffect(() => {
  //   // IS USER IS NOT LOGGED IN THEN REDIRECT TO LOGIN PAGE
  //   if (!userInfo) {
  //     history.push("/login");
  //   }

  //   // CHECK IF WE HAVE THE ORDER DETAILS, IF NOT DISPATCH AN ACTION TO GET THE ORDER DETAILS
  //   if (
  //     !order ||
  //     successPay ||
  //     order._id !== Number(orderId) ||
  //     successDeliver
  //   ) {
  //     dispatch({ type: ORDER_PAY_RESET });

  //     dispatch({ type: ORDER_DELIVER_RESET });

  //     dispatch(getOrderDetails(orderId));
  //   } else if (!order.isPaid) {
  //     // ACTIVATING PAYPAL SCRIPTS
  //     if (!window.paypal) {
  //       addPayPalScript();
  //     } else {
  //       setSdkReady(true);
  //     }
  //   }
  // }, [dispatch, order, orderId, successPay, successDeliver, history, userInfo]);

  /* HANDLERS */
  // const successPaymentHandler = (paymentResult) => {
  //   dispatch(payOrder(orderId, paymentResult));
  // };

  const deliverHandler = () => {
    dispatch(deliverOrder(order));
  };

  return loading ? (
    <Loader />
    ) : error ? (
      <Message variant="danger">{error}</Message>
    ) : (
      <>
      <div className="container p-t-75 p-b-75">
        <div className="bread-crumb flex-w p-l-25 p-r-15 p-t-30 p-lr-0-lg">
          <div className="container p-t-40">
            <Row>
              <Col md={8}>
                <Card>
                  <ListGroup variant="flush">
                    <div className="container text-center justify-content-center p-t-30 p-b-30">
                      <img src="static/images/orderSuccess.png" alt="Generic placeholder image" width={140} height={140} />
                      <h4 className="p-t-20 text-dark font-weight-bold">Your Order is Completed Successfully!</h4>
                      <p>You will be receiving a confirmation email or call with order details</p>
                    </div>
                    <ListGroup.Item>
                      <h4 className="p-b-20 p-t-20 font-weight-bolder"><strong>ORDER NO: {order._id}</strong></h4>

                      <h3 className="p-t-20 p-b-20">Shipping</h3>

                      <p>
                        <strong>Name:</strong> {order.User.name}
                      </p>

                      <p>
                        <strong>Email: </strong>
                        <a href={`mailto:${order.User.email}`}>{order.User.email}</a>
                      </p>

                      <p className="p-b-20">
                        <strong>Shipping Address: </strong>
                        {order.shippingAddress.address}, {order.shippingAddress.city},{" "}
                        {order.shippingAddress.postalCode},{" "}
                        {order.shippingAddress.country}
                      </p>

                      {order.isDeliver ? (
                        <Message variant="success">
                          Delivered on{" "}
                          {order.deliveredAt
                            ? order.deliveredAt.substring(0, 10)
                            : null}
                        </Message>
                      ) : (
                        <Message variant="warning">Not Delivered</Message>
                      )}
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <h3 className="p-t-20 p-b-20">Payment</h3>

                      <p className="p-b-20">
                        <strong>Payment Method: </strong>
                        {order.paymentMethod}
                      </p>

                      {order.isPaid ? (
                        <Message variant="success">
                          Paid on {order.paidAt ? order.paidAt.substring(0, 10) : null}
                        </Message>
                      ) : (
                        <Message variant="warning">Not Paid</Message>
                      )}
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <h3 className="p-t-20 p-b-20">Order Items</h3>

                      {order.orderItems.length === 0 ? (
                        <Message variant="info">Order is empty</Message>
                      ) : (
                        <ListGroup variant="flush">
                          {order.orderItems.map((item, index) => (
                            <ListGroup.Item key={index}>
                              <Row>
                                <Col md={1}>
                                  <Image
                                    src={item.image}
                                    alt={item.name}
                                    fluid
                                    rounded
                                  />
                                </Col>

                                <Col>
                                  <Link to={`/product/${item.product}`}>
                                    {item.name}
                                  </Link>
                                </Col>

                                <Col md={4}>
                                  {item.qty} X Tk {item.price} = Tk 
                                  {(item.qty * item.price).toFixed(2)}
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      )}
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              </Col>

              <Col md={4}>
                <Card>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <h2>Order Summary</h2>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col className="font-weight-bold">Items:</Col>

                        <Col>Tk {order.itemsPrice}</Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col className="font-weight-bold" >Shipping:</Col>

                        <Col>Tk {order.shippingPrice}</Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col className="font-weight-bold">Tax:</Col>

                        <Col>Tk {order.taxPrice}</Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col className="font-weight-bold">Total:</Col>

                        <Col>Tk {order.totalPrice}</Col>
                      </Row>
                    </ListGroup.Item>

                    {/* {!order.isPaid && (
                      <ListGroup.Item>
                        {loadingPay && <Loader />}
                        {!sdkReady ? (
                          <Loader />
                        ) : (
                          <PayPalButton
                            amount={order.totalPrice}
                            onSuccess={successPaymentHandler}
                          />
                        )}
                      </ListGroup.Item>
                    )} */}
                  </ListGroup>

                  {loadingDeliver && <Loader />}

                  {userInfo && userInfo.isAdmin && order.isPaid && !order.isDeliver && (
                    <ListGroup.Item>
                      <Button
                        type="button"
                        className="btn w-100"
                        onClick={deliverHandler}
                      >
                        Mark As Delivered
                      </Button>
                    </ListGroup.Item>
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      </>
  );
}

export default OrderScreen;
