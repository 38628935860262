import React from 'react'

export default function ContactScreen() {
  return (
    <div>
            {/* Title page */}
            <section className="bg-img1 txt-center p-lr-15 p-tb-92" style={{backgroundImage: 'url("static/images/bg-01.jpg")'}}>
                <h2 className="ltext-105 cl0 txt-center text-dark">
                Contact
                </h2>
            </section>	
            {/* Content page */}
            <section className="bg0 p-t-104 p-b-116">
                <div className="container">
                <div className="flex-w flex-tr">
                    <div className="size-210 bor10 p-lr-70 p-t-55 p-b-70 p-lr-15-lg w-full-md text-center">
                        <h4 className="font-weight-bold mtext-105 cl2 text-center">
                            <strong className='text-danger '>P</strong>REFACE
                        </h4>
                        <p>At prefacebd.com, also known as the "Service," we value your privacy and want to ensure that your visit to our website is safe and secure. This Privacy Policy outlines how we collect, protect, and disclose information that we gather through our Service.</p>

                       <p>When you use our Service, you trust us with your information, and we strive to handle it responsibly. By using our Service, you agree to the collection and use of information as described in this Privacy Policy. The terms used in this policy have the same definitions as in our Terms and Conditions, unless otherwise specified.
                        </p>
                       
                    </div>
                    <div className="size-210 bor10 flex-w flex-col-m p-lr-93 p-tb-30 p-lr-15-lg w-full-md">
                    <div className="flex-w w-full p-b-42">
                        <span className="fs-18 cl5 txt-center size-211">
                        <span className="lnr lnr-map-marker" />
                        </span>
                        <div className="size-212 p-t-2">
                        <span className="mtext-110 cl2">
                            Address
                        </span>
                        <p className="stext-115 cl6 size-213 p-t-18">
                        Madambibirhat, Bhatiary, Sitakunda, Chittagong
                        </p>
                        </div>
                    </div>
                     <div className="flex-w w-full p-b-42">
                        <span className="fs-18 cl5 txt-center size-211">
                        <span className="lnr lnr-phone-handset" />
                        </span>
                        <div className="size-212 p-t-2">
                        <span className="mtext-110 cl2">
                            Lets Talk
                        </span>
                        <p className="stext-115 cl1 size-213 p-t-18">
                        +880 1326-757876
                        </p>
                        </div>
                    </div>
                    <div className="flex-w w-full">
                        <span className="fs-18 cl5 txt-center size-211">
                        <span className="lnr lnr-envelope" />
                        </span>
                        <div className="size-212 p-t-2">
                        <span className="mtext-110 cl2">
                            Sale Support
                        </span>
                        <p className="stext-115 cl1 size-213 p-t-18">
                        prefacebd2322@gmail.com
                        </p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
        </div>
  )
}
