/* ACTION TYPES USED IN CartScreen COMPONENT */

// ADD ITEMS TO CART
export const CART_ADD_ITEM = "CART_ADD_ITEM";

// REMOVE ITEMS FROM CART
export const CART_REMOVE_ITEM = "CART_REMOVE_ITEM";

/* ACTION TYPES USED IN ShippingScreen COMPONENT */

// SAVE SHIPPING ADDRESS
export const CART_SAVE_SHIPPING_ADDRESS = "CART_SAVE_SHIPPING_ADDRESS";

// SAVE SHIPPING ADDRESS
export const CART_SAVE_PAYMENT_METHOD = "CART_SAVE_PAYMENT_METHOD";

// DELETE CART INFO IN LOCAL STORAGE AFTER ORDER PLACED
export const CART_CLEAR_ITEMS = "CART_CLEAR_ITEMS";
