import React, { useState, useEffect } from "react";

/* REACT BOOTSTRAP */
import { Button} from "react-bootstrap";

/* REACT ROUTER BOOTSTRAP */
import { LinkContainer } from "react-router-bootstrap";

/* COMPONENTS */
import Message from "../components/Message";
import Loader from "../components/Loader";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

/* ACTION CREATORS */
import { getUserDetails, updateUserProfile } from "../actions/userActions";

import { listMyOrders } from "../actions/orderActions";

/* ACTION TYPES */
import { USER_UPDATE_PROFILE_RESET } from "../constants/userConstants";

function ProfileScreen({ history }) {
  /* STATE */
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();

  /* PULLING A PART OF STATE FROM THE ACTUAL STATE IN THE REDUX STORE */
  const userDetails = useSelector((state) => state.userDetails);

  const { user, loading, error } = userDetails;

  /* WE NEED TO MAKE SURE USER IS LOGGED IN SO PULLING OUT USER LOGIN INFO */
  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  /* PULLING OUT SUCCESS FROM userUpdateProfile, IF SUCCESS IS TRUE WE WILL RESET STATE */
  const userUpdateProfle = useSelector((state) => state.userUpdateProfle);

  const { success } = userUpdateProfle;

  /* PULLING OUT USER ORDER DETAILS TO DISPLAY ON THE PAGE */
  const orderListMy = useSelector((state) => state.orderListMy);

  const { loading: loadingOrders, error: errorOrders, orders } = orderListMy;

  /* SENDING USER TO LOGIN PAGE IF NOT LOGGED IN & SHOW PROFILE DATA IF LOGGED IN */
  useEffect(() => {
    // USER IS NOT LOGGED IN
    if (!userInfo) {
      history.push("/login");
    } else {
      // WE DON'T HAVE THE USER INFO SO WE DISPATCH AN ACTION TO GET THE DATA
      if (!user || !user.name || success || userInfo._id !== user._id) {
        /* (userInfo._id !== user._id) BECAUSE DURING USER EDIT STATE CHANGES SO WE WANT TO FIRE DISPATCH AGAIN HERE IF THE DATA ISN'T SAME AS THE USER AS WE ARE LOGGED IN  */
        // RESETTING PROFILE BEFORE FETCHING DATA SO THAT WE ALWAYS HAVE UPDATED DATA
        dispatch({ type: USER_UPDATE_PROFILE_RESET });

        // FETCHING USER DATA
        dispatch(getUserDetails("profile"));

        // FETCHING USER ORDER DETAILS
        dispatch(listMyOrders());
      } else {
        // WE HAVE THE USER INFO SO WE SET OUR STATE
        setName(user.name);
        setEmail(user.email);
      }
    }
  }, [dispatch, history, userInfo, user, success]);

  /* HANDLERS */

  const submitHandler = (e) => {
    e.preventDefault();

    /* DISABLE SUBMIT IF PASSWORDS DON'T MATCH */
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
    } else {
      dispatch(
        updateUserProfile({
          id: user._id,
          name: name,
          email: email,
          password: password,
        })
      );
      setMessage("");
    }
  };

  return (
    <>
      <div className="container p-t-75 p-b-75">
        <div className="bread-crumb p-l-25 p-r-15 p-t-30 p-lr-0-lg">
          <div className="row p-t-40">
            <div className="col-md-4 text-center col-sm-12">
                <img className="rounded-circle " src="static/images/p.png" alt="Generic placeholder" width={60} height={60} />
                <h4 className="p-t-20 p-b-20 text-uppercase">{name}</h4>
                <p>Hello {name}!, Welcome to user Dashboard</p>
                {message && <Message variant="danger">{message}</Message>}
                {error && <Message variant="danger">{error}</Message>}
                {loading && <Loader />}
            </div>
            <div className="col-md-8 text-center col-sm-12">
              <h4 className="p-t-20 p-b-20 text-uppercase">{name} Order's</h4>
              <div className="container">
                {loadingOrders ? (
                  <Loader />
                ) : errorOrders ? (
                  <Message variant="danger">{errorOrders}</Message>
                ) : (
                  <table className="table table-striped table-bordered table-sm table-hover">
                    <thead>
                      <tr>
                        <th className="text-center">ID</th>
                        <th className="text-center">Date</th>
                        <th className="text-center">Total</th>
                        <th className="text-center">Paid</th>
                        <th className="text-center">Delivered</th>
                      </tr>
                    </thead>
                    <tbody>
                        {orders.map((order) => (
                          <tr className="text-center" key={order._id}>
                            <td>{order._id}</td>
                            <td>
                              {order.createdAt ? order.createdAt.substring(0, 10) : null}
                            </td>
                            <td>${order.totalPrice}</td>
                            <td>
                              {order.isPaid ? (
                                order.paidAt ? (
                                  order.paidAt.substring(0, 10)
                                ) : null
                              ) : (
                                <i className="fa fa-times" style={{ color: "red" }}></i>
                              )}
                            </td>
                            <td>
                              <LinkContainer to={`/order/${order._id}`}>
                                <Button className="btn-sm btn-danger">View Details</Button>
                              </LinkContainer>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                  </table>
                )};
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileScreen;
