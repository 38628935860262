import React, { useEffect } from "react";
import { Link } from 'react-router-dom';

/* COMPONENTS */
import Product from "../components/Product";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Paginate from "../components/Paginate";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

/* ACTION CREATORS */
import { listProducts } from "../actions/productActions";


function HomeScreen({ history }) {
  const dispatch = useDispatch();

  /* PULLING A PART OF STATE FROM THE ACTUAL STATE IN THE REDUX STORE */
  const productList = useSelector((state) => state.productList);
  const { products, page, pages, loading, error } = productList;

  /* FIRING OFF THE ACTION CREATORS USING DISPATCH */

  let keyword =
    history.location
      .search; /* IF USER SEARCHES FOR ANYTHING THEN THIS KEYWORD CHANGES AND USE EFFECT GETS TRIGGERED */

  useEffect(() => {
    dispatch(listProducts(keyword));
  }, [dispatch, keyword]);

  return (
    <>
    <div>
            <section className="bg-img1 txt-center p-lr-15 p-tb-92" style={{backgroundImage: 'url("static/images/bg-01.jpg")'}}>
                <h2 className="ltext-105 cl0 txt-center text-dark">
                    SHOP
                </h2>
            </section>	
        <section className="bg0 p-t-30 p-b-140 mt-5">
            <div className="container">
                <div className="p-b-10">
                </div>
                <div className="flex-w flex-sb-m p-b-10">
                <div className="flex-w flex-l-m filter-tope-group m-tb-10">
                    <Link to={'/shop'} className="stext-106 cl6 hov1 bor3 trans-04 m-r-32 m-tb-5 how-active1" data-filter="*">
                    All Products
                    </Link>
                    <Link to={'/shop/?keyword=preface edition&page=1'} className="stext-106 cl6 hov1 bor3 trans-04 m-r-32 m-tb-5">
                    PREFACE EDITION 
                    </Link>
                    <Link to={'/shop/?keyword=fan edition&page=1'} className="stext-106 cl6 hov1 bor3 trans-04 m-r-32 m-tb-5" >
                    FAN EDITION
                    </Link>
                    <Link to={'/shop/?keyword=custom edition&page=1'} className="stext-106 cl6 hov1 bor3 trans-04 m-r-32 m-tb-5">
                    CUSTOMISED EDITION
                    </Link>
                </div>
                <div className="flex-w flex-c-m m-tb-10">
                    
                </div>
                {/* Search product */}
                <div className="dis-none panel-search w-full p-t-10 p-b-15">
                    <div className="bor8 dis-flex p-l-15">
                    <button className="size-113 flex-c-m fs-16 cl2 hov-cl1 trans-04">
                        <i className="zmdi zmdi-search" />
                    </button>
                    <input className="mtext-107 cl2 size-114 plh2 p-r-15" type="text" name="search-product" placeholder="Search" />
                    </div>	
                </div>
                {/* Filter */}
                
                </div>
                <div className="row isotope-grid">
                <div className="container">
            <div className="p-b-2">
              <h3 className="ltext-103 cl5 text-center mb-4">
                  
              </h3>
            </div>
                {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message variant="danger">{error}</Message>
                  ) : (
                   <div className="container">
                      <div className="row isotope-grid">
                        {products.map((product) => {
                          return (
                              <div key={product._id} className="col-sm-6 col-md-4 col-lg-3 p-b-35 isotope-item women">
                                <div className="block2">
                                  <Product product={product} />
                                </div>
                              </div>
                            );
                        })}
                      </div>
                    </div>
                )}
        </div>
                
                </div>
                <div className="flex-c-m flex-w w-full p-t-45">
                    <Paginate page={page} pages={pages} keyword={keyword} />
                </div>
            </div>
        </section>
    </div>
    </>
  );
}

export default HomeScreen;
