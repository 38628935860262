import React, { useEffect, useState } from 'react';
import { API_URL, TERMS_ENDPOINT } from '../apiConfig';
import Loader from '../components/Loader';
import DOMPurify from 'dompurify';

export default function TermScreen() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(`${API_URL}${TERMS_ENDPOINT}`);
            if (!response.ok) {
              throw new Error('Request failed');
            }
            const jsonData = await response.json();
            setData(jsonData);
            setLoading(false);
          } catch (error) {
            setError(error.message);
            setLoading(false);
          }
        };
    
        fetchData();
      }, []);
    
      if (loading) {
        return <div className="container p-t-70 p-b-70 text-center"><Loader/></div>;
      }
    
      if (error) {
        return <div className="container p-t-70 p-b-70 text-center">Error: {error}</div>;
      }

      const sanitizeHTML = (html) => {
        const sanitizedHTML = DOMPurify.sanitize(html);
        return { __html: sanitizedHTML };
      };

  return (
    <div>
        <div>
            <section className="bg-img1 txt-center p-lr-15 p-tb-92" style={{backgroundImage: 'url("static/images/bg-01.jpg")'}}>
                <h2 className="ltext-105 cl0 txt-center text-dark">
                    TERMS & CONDITIONS
                </h2>
            </section>	
            {/* Content page */}
            <section className="bg0 p-t-30 p-b-30 p-4">
                <div className="container">
                {data.map((item) => (
                    <div key={item.id} className="row p-b-30">
                        <div className="col-md-12 col-lg-12">
                            <div className="p-t-7 p-r-15-lg p-r-0-md">
                                <h3 className="mtext-111 cl2 p-b-16">
                                {item.termsTitle}
                                </h3>
                                <p
                                  className="stext-113 cl6 p-b-26"
                                  dangerouslySetInnerHTML={sanitizeHTML(item.termsDescription)}
                                />
                            </div>
                        </div>
                    </div>
                ))}
                </div>
            </section>
        </div>
    </div>
  )
}
