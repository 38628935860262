import React, { useEffect, useState } from "react";

/* REACT ROUTER */
import { Link } from "react-router-dom";

/* REACT BOOTSTRAP */
import {
  Form,
} from "react-bootstrap";

/* COMPONENTS */
import Rating from "../components/Rating";
import Loader from "../components/Loader";
import Message from "../components/Message";
import DOMPurify from 'dompurify';

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

/* ACTION CREATORS */
import {
  listProductDetails,
  createProductReview,
} from "../actions/productActions";

/* ACTION TYPES */
import { PRODUCT_CREATE_REVIEW_RESET } from "../constants/productConstants";

function ProductScreen({ match, history }) {
  /* STATE */
  const [qty, setQty] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const dispatch = useDispatch();

  /* PULLING A PART OF STATE FROM THE ACTUAL STATE IN THE REDUX STORE */
  const productDetails = useSelector((state) => state.productDetails);
  const { product, loading, error } = productDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const {
    success: successProductReview,
    loading: loadingProductReview,
    error: errorProdcutReview,
  } = productReviewCreate;

  /* FIRING OFF THE ACTION CREATORS USING DISPATCH */

  useEffect(() => {
    // IF REVIEW SUCCESSFULLY SUBMITTED, RESET
    if (successProductReview) {
      setRating(0);
      setComment("");
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
    }

    dispatch(listProductDetails(match.params.id));
  }, [dispatch, match, successProductReview]);

  const addToCartHandler = () => {
    history.push(`/cart/${match.params.id}?qty=${qty}`);
  };

  /* HANDLERS */
  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(createProductReview(match.params.id, { rating, comment }));
  };
  const sanitizeHTML = (html) => {
    const sanitizedHTML = DOMPurify.sanitize(html);
    return { __html: sanitizedHTML };
  };


  return (
    <div>
      <div>
        <div>
        <section className="bg-img1 txt-center p-lr-15 p-tb-92" style={{backgroundImage: 'url("static/images/bg-01.jpg")'}}>
                <h2 className="ltext-105 cl0 txt-center text-dark">
                    PRODUCT
                </h2>
            </section>
          {/* breadcrumb */}
          <div className="container p-t-30 mt-5">
            <div className="bread-crumb flex-w p-l-25 p-r-15 p-t-30 p-lr-0-lg">
              <a href="index.html" className="stext-109 cl8 hov-cl1 trans-04">
                HOME
                <i className="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true" />
              </a>
              <a href="product.html" className="stext-109 cl8 hov-cl1 trans-04">
                SHOP
                <i className="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true" />
              </a>
              <span className="stext-109 cl4">
              {product.name}
              </span>
            </div>
          </div>
          {/* Product Detail */}
          <section className="sec-product-detail bg0 p-t-65 p-b-60">
          {loading ? (
          <Loader />
            ) : error ? (
              <Message variant="danger">{error}</Message>
            ) : (
            <div>
              <div className="container">
                <div className="row">
                  <div className="col-md-6 col-lg-7 p-b-30">
                    <div className="p-l-25 p-r-30 p-lr-0-lg">
                      <div className="wrap-slick3">
                        <div className="wrap-slick3-dots" />
                        <div className="wrap-slick3-arrows flex-sb-m flex-w" />
                        <div className=" gallery-lb">
                          <div className="item-slick3" data-thumb="images/product1.png">
                            <div className="wrap-pic-w pos-relative">
                              <img src={product.image} alt={product.name} />
                              <a className="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04" href={product.image}>
                                <i className="fa fa-expand" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-5 p-b-30">
                    <div className="p-r-50 p-t-5 p-lr-0-lg">
                      <h4 className="mtext-105 cl2 js-name-detail p-b-8">
                      {product.name}
                      </h4>
                      <p className="cl2 font-weight-bold p-b-14">SKU: {product.serialNumber}</p>
                      <div className="flex-w flex-sb-m p-b-17">
                        <Rating
                            value={product.rating}
                            text={` ${product.numReviews} reviews`}
                            color={"#f8e825"}
                          />
                      </div>
                      
                      <span className="mtext-106 cl2">
                      TK {product.price}
                      </span>
                      
                      <p className="stext-102 cl3 p-t-23" dangerouslySetInnerHTML={sanitizeHTML(product.description)}>
                      </p>
                      {/*  */}
                      {product.countInStock > 0 && (
                        <div className="mt-5">
                          <div className="flex-w flex-sb-m p-b-17">
                              <span className="mtext-107 cl2 p-r-20 font-weight-bold">
                              Status:
                              </span>
                              <span className="fs-18 cl11">
                              {product.countInStock > 0 ? "In Stock" : "Out of Stock"}
                              </span>
                          </div>
                          <div className="flex-w flex-sb-m p-b-17">
                              <span className="mtext-107 cl2 p-r-20 font-weight-bold">
                              Quantity:
                              </span>
                              <span className="fs-18 cl11">
                              <Form.Control
                                  as="select"
                                  value={qty}
                                  onChange={(e) => setQty(e.target.value)}
                                >
                                  {[...Array(product.countInStock).keys()].map(
                                    (x) => (
                                      <option key={x + 1} value={x + 1}>
                                        {x + 1}
                                      </option>
                                    )
                                  )}
                                </Form.Control>
                              </span>
                          </div>
                        </div>
                      )}
                      <div className="p-t-33">
                          <div className="size-204 flex-w flex-l-m respon6-next">
                            <button className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail" disabled={product.countInStock === 0} onClick={addToCartHandler} type="button">
                              Add to cart
                            </button>
                          </div>	
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div className="bor10 m-t-50 p-t-43 p-b-40">
                  {/* Tab01 */}
                  <div className="tab01">
                    {/* Nav tabs */}
                    <ul className="nav nav-tabs" role="tablist">
                      <li className="nav-item p-b-10">
                        <a className="nav-link active" data-toggle="tab" href="#reviews" role="tab">Reviews (
                          {`${product.numReviews}`})
                        </a>
                      </li>
                    </ul>
              
                    <div className="tab-content p-t-43">
                      <div className="tab-pane fade" id="reviews" role="tabpanel">
                        <div className="row">
                          <div className="col-sm-10 col-md-8 col-lg-6 m-lr-auto">
                            <div className="p-b-30 m-lr-15-sm">
                            {product.reviews.map((review) => (
                              <div key={review._id} className="flex-w flex-t p-b-68">
                                <div className="wrap-pic-s size-109 bor0 of-hidden m-r-18 m-t-6">
                                  <img src="static/images/p.png" alt="img" />
                                </div>
                                <div className="size-207">
                                  
                                  <div className="flex-w flex-sb-m p-b-17">
                                    <span className="mtext-107 cl2 p-r-20">
                                    {review.name} <br/><small>{review.createdAt.substring(0, 10)}</small>
                                    </span>
                                    
                                    <span className="fs-18 cl11">
                                    <Rating value={review.rating} color="f8e825" />
                                    </span>
                                  </div>
                                  <p className="stext-102 cl6">
                                  {review.comment}
                                  </p>
                                </div>
                              </div>
                            ))}
                              
                              {product.reviews.length === 0 && (
                                  <Message variant="info">No Reviews</Message>
                                )}
                                {loadingProductReview && <Loader />}
                                {successProductReview && (
                                  <Message variant="success">Review Submitted</Message>
                                )}
                                {errorProdcutReview && (
                                  <Message variant="danger">{errorProdcutReview}</Message>
                                )}
                            {userInfo ? (
                              <form className="w-full" onSubmit={submitHandler}>
                                <h5 className="mtext-108 cl2 p-b-7">
                                  Add a review
                                </h5>
                                
                                <p className="stext-102 cl6">
                                  Your email address will not be published. Required fields are marked *
                                </p>
                                
                                <div className="row p-b-25">
                                  <div className="col-12 p-b-5">
                                    <label className="stext-102 cl3" htmlFor="review">Your review</label>
                                    <select 
                                    className="form-control p-2" as="select"
                                    value={rating}
                                    onChange={(e) => setRating(e.target.value)}>
                                    <option value="">Select Rating...</option>
                                    <option value="1">1 - Poor</option>
                                    <option value="2">2 - Fair</option>
                                    <option value="3">3 - Good</option>
                                    <option value="4">4 - Very Good</option>
                                    <option value="5">5 - Excellent</option>
                                    </select>
                                  </div>
                                  
                                  <div className="col-sm-12 p-b-5">
                                    <label className="stext-102 cl3" htmlFor="name">Comment</label>
                                    <textarea className="size-110 bor8 stext-102 cl2 p-lr-20 p-tb-10" as="textarea"
                                    row="5"
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)} />
                                  </div>
                                
                                </div>
                                <button className="flex-c-m stext-101 cl0 size-112 bg7 bor11 hov-btn3 p-lr-15 trans-04 m-b-10" disabled={loadingProductReview}
                                type="submit">
                                  Submit
                                </button>
                              </form>
                            ) : (
                            <Message variant="info">
                              Please <Link to="/login">Login</Link> to write a review.
                            </Message>
                            )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg6 flex-c-m flex-w size-302 m-t-73 p-tb-15">
                <span className="stext-107 cl6 p-lr-25">
                  SKU: {product.serialNumber}
                </span>
                <span className="stext-107 cl6 p-lr-25">
                  Categories: {product.category}
                </span>
              </div>
            </div>
            )}
          </section>
        </div>
      </div>
     
    </div>
  );
}

export default ProductScreen;
