import React from 'react'

export const Rating = () => {
  return (
    <div>
        {/* Customer Review  */}
        <div className="container p-b-23 p-t-23">
        <h3 className="mtext-111 cl2 p-b-50 text-center">WHAT OUR CLIENT SAYS ABOUT US</h3>
        <div className="container review-design p-t-23">
            <div className="row">
            <div className="col-md-6">
                <div className="flex-w flex-t p-b-23 ">
                <div className="wrap-pic-s size-109 bor0 of-hidden m-r-18 m-t-6">
                    <img src="static/images/p.png" alt="img" />
                </div>
                <div className="size-207">
                    <div className="flex-w flex-sb-m p-b-17">
                    <span className="mtext-107 cl2 p-r-20">
                        Tanjir Hossain
                    </span>
                    <span className="fs-18 cl11">
                        <i className="zmdi zmdi-star" />
                        <i className="zmdi zmdi-star" />
                        <i className="zmdi zmdi-star" />
                        <i className="zmdi zmdi-star" />
                        <i className="zmdi zmdi-star-half" />
                    </span>
                    </div>
                    <p className="stext-102 cl6">
                    "PrefaceBD.com has completely revolutionized the way I network. Their NFC business cards are not only sleek and professional, but the interactive features are a game-changer. I love how easily recipients can access my contact information with a simple tap, making it effortless to stay connected. Highly recommend!"
                    </p>
                </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="flex-w flex-t p-b-23 ">
                <div className="wrap-pic-s size-109 bor0 of-hidden m-r-18 m-t-6">
                    <img src="static/images/p.png" alt="img" />
                </div>
                <div className="size-207">
                    <div className="flex-w flex-sb-m p-b-17">
                    <span className="mtext-107 cl2 p-r-20">
                        Raihan
                    </span>
                    <span className="fs-18 cl11">
                        <i className="zmdi zmdi-star" />
                        <i className="zmdi zmdi-star" />
                        <i className="zmdi zmdi-star" />
                        <i className="zmdi zmdi-star" />
                        <i className="zmdi zmdi-star" />
                    </span>
                    </div>
                    <p className="stext-102 cl6">
                    "I can't say enough good things about PrefaceBD.com. Their NFC business cards have elevated my networking experience to a whole new level. The convenience and efficiency of sharing contact information digitally are unmatched. Plus, the customer service is top-notch. They guided me through the design process and delivered a product that exceeded my expectations. I'm thrilled with my Preface cards!"
                    </p>
                </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="flex-w flex-t p-b-23 ">
                <div className="wrap-pic-s size-109 bor0 of-hidden m-r-18 m-t-6">
                    <img src="static/images/p.png" alt="img" />
                </div>
                <div className="size-207">
                    <div className="flex-w flex-sb-m p-b-17">
                    <span className="mtext-107 cl2 p-r-20">
                        Ahmed Mahim
                    </span>
                    <span className="fs-18 cl11">
                        <i className="zmdi zmdi-star" />
                        <i className="zmdi zmdi-star" />
                        <i className="zmdi zmdi-star" />
                        <i className="zmdi zmdi-star-half" />
                        <i className="zmdi zmdi-star-half" />
                    </span>
                    </div>
                    <p className="stext-102 cl6">
                   "PrefaceBD.com has truly embraced the future of business cards. Their NFC technology is cutting-edge and sets them apart from the competition. The ability to seamlessly share digital content along with contact information is a brilliant concept. The cards are beautifully crafted, and the attention to detail is evident. If you want to make a statement in networking, Preface is the way to go."
                    </p>
                </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="flex-w flex-t p-b-23 ">
                <div className="wrap-pic-s size-109 bor0 of-hidden m-r-18 m-t-6">
                    <img src="static/images/p.png" alt="img" />
                </div>
                <div className="size-207">
                    <div className="flex-w flex-sb-m p-b-17">
                    <span className="mtext-107 cl2 p-r-20">
                        Foyzul Karim
                    </span>
                    <span className="fs-18 cl11">
                        <i className="zmdi zmdi-star" />
                        <i className="zmdi zmdi-star" />
                        <i className="zmdi zmdi-star" />
                        <i className="zmdi zmdi-star" />
                        <i className="zmdi zmdi-star-half" />
                    </span>
                    </div>
                    <p className="stext-102 cl6">
                    "PrefaceBD.com exceeded my expectations with their NFC business cards. The quality of the cards is exceptional, and the customization options allowed me to create a unique design that perfectly represents my brand. It's incredible how much impact a simple tap can have in leaving a lasting impression. I'm impressed!"
                    </p>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>

    </div>
  )
}
