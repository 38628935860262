import React from 'react'
import { Link } from 'react-router-dom';

export const CategoryHome = () => {
  return (
    <>
    <div className="sec-banner bg0 p-t-80 p-b-23">
    <div className="container">
        <div className="row">
        <div className="col-md-6 col-xl-4 p-b-30 m-lr-auto">
            {/* Block1 */}
            <div className="block1 wrap-pic-w">
            <img src="static/images/prefaceEdition.png" alt="IMG-BANNER" />
            <Link to='/shop' className="block1-txt ab-t-l s-full flex-col-l-sb p-lr-38 p-tb-34 trans-03 respon3">
               
                <div className="block1-txt-child2 p-b-4 trans-05">
                <div className="block1-link stext-101 cl0 trans-09">
                PREFACE EDITION 
                </div>
                </div>
            </Link>
            </div>
        </div>
        <div className="col-md-6 col-xl-4 p-b-30 m-lr-auto">
            {/* Block1 */}
            <div className="block1 wrap-pic-w">
            <img src="static/images/fanEdition.png" alt="IMG-BANNER" />
            <Link to='/shop' className="block1-txt ab-t-l s-full flex-col-l-sb p-lr-38 p-tb-34 trans-03 respon3">
                <div className="block1-txt-child2 p-b-4 trans-05">
                <div className="block1-link stext-101 cl0 trans-09">
                FAN EDITION
                </div>
                </div>
            </Link>
            </div>
        </div>
        <div className="col-md-6 col-xl-4 p-b-30 m-lr-auto">
            {/* Block1 */}
            <div className="block1 wrap-pic-w">
            <img src="static/images/customEdition.png" alt="IMG-BANNER" />
            <Link to='/shop' className="block1-txt ab-t-l s-full flex-col-l-sb p-lr-38 p-tb-34 trans-03 respon3">
                <div className="block1-txt-child2 p-b-4 trans-05">
                <div className="block1-link stext-101 cl0 trans-09">
                    CUSTOMISE EDITION
                </div>
                </div>
            </Link>
            </div>
        </div>
        </div>
    </div>
    </div>

    </>
  )
}
