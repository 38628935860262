import React from "react";
import { Link } from 'react-router-dom';


function Footer() {
  return (
    <div>
        <footer className="bg3 p-t-75 p-b-32">
        <div className="container">
            <div className="row">
            <div className="col-sm-6 col-lg-3 p-b-50">
                <h4 className="stext-301 cl0 p-b-30">
                PREFACE
                </h4>
                <ul style={{listStyle:'none'}}>
                <li className="p-b-10">
                    <Link to={'/about'} className="stext-107 cl7 hov-cl1 trans-04">
                    About
                    </Link>
                </li>
                <li className="p-b-10">
                    <Link to={'/contact'} className="stext-107 cl7 hov-cl1 trans-04">
                    Contact
                    </Link>
                </li>
                </ul>
            </div>
            <div className="col-sm-6 col-lg-3 p-b-50">
                <h4 className="stext-301 cl0 p-b-30">
                Help
                </h4>
                <ul style={{listStyle:'none'}}>
                <li className="p-b-10">
                    <Link to="/terms" className="stext-107 cl7 hov-cl1 trans-04">
                    Terms &amp; Conditions
                    </Link>
                </li>
                <li className="p-b-10">
                    <Link to="/return" className="stext-107 cl7 hov-cl1 trans-04">
                    Returns &amp; Refunds 
                    </Link>
                </li>
                <li className="p-b-10">
                    <Link to="/privacy" className="stext-107 cl7 hov-cl1 trans-04">
                    Privacy Policy
                    </Link>
                </li>
                
                <li className="p-b-10">
                    <Link to="/faq" className="stext-107 cl7 hov-cl1 trans-04">
                    FAQs
                    </Link>
                </li>
                </ul>
            </div>
            <div className="col-sm-6 col-lg-3 p-b-50">
                <h4 className="stext-301 cl0 p-b-30">
                GET IN TOUCH
                </h4>
                <p className="stext-107 cl7 size-201">
                Any questions? Let us know in store at Madambibirhat, Sitakunda, Chittagong or call us on (+880)  1706118493
                </p>
                <div className="p-t-27">
                    <a href="https://www.facebook.com/profile.php?id=100090621589212&mibextid=ZbWKwL" className="fs-18 cl7 hov-cl1 trans-04 m-r-16" target='_blank'>
                        <i className="fa fa-facebook" />
                    </a>
                    <a href="https://instagram.com/preface_bd?igshid=NGExMmI2YTkyZg==" className="fs-18 cl7 hov-cl1 trans-04 m-r-16" target='_blank'>
                        <i className="fa fa-instagram" />
                    </a>
                    <a href="https://twitter.com/PrefaceBd?t=r0UXDBzap_a23tW-jbQivQ&s=09" className="fs-18 cl7 hov-cl1 trans-04 m-r-16" target='_blank'>
                        <i className="fa fa-twitter" />
                    </a>
                    <a href="https://www.youtube.com/@PrefaceBD" className="fs-18 cl7 hov-cl1 trans-04 m-r-16" target='_blank'>
                        <i className="fa fa-youtube" />
                    </a>
                </div>
            </div>
            <div className="col-sm-6 col-lg-3 p-b-50">
               
            </div>
            </div>
            <div className="p-t-40">
            <div className="flex-c-m flex-w p-b-18">
                <Link to="#" className="m-all-1">
                <img src="static/images/icons/icon-pay-01.png" alt="ICON-PAY" />
                </Link>
                <Link to="#" className="m-all-1">
                <img src="static/images/icons/icon-pay-02.png" alt="ICON-PAY" />
                </Link>
                <Link to="#" className="m-all-1">
                <img src="static/images/icons/icon-pay-03.png" alt="ICON-PAY" />
                </Link>
                <Link to="#" className="m-all-1">
                <img src="static/images/icons/icon-pay-04.png" alt="ICON-PAY" />
                </Link>
                <Link to="#" className="m-all-1">
                <img src="static/images/icons/icon-pay-05.png" alt="ICON-PAY" />
                </Link>
            </div>
            <p className="stext-107 cl6 txt-center">
                Copyright © All rights reserved by Preface | Developed <i className="fa fa-heart-o" aria-hidden="true" /> by <a href="https://www.linkedin.com/in/md-sayem-uddin-bb40991ba/" target="_blank" className="text-light font-weight-bold">MD SAYEM UDDIN</a>
            </p>
            </div>
        </div>
        </footer>
    </div>
  );
}

export default Footer;
