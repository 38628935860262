import React, { useEffect, useState } from 'react';
import { API_URL, ABOUT_ENDPOINT } from '../apiConfig';
import AboutTeam from '../components/AboutTeam';
import Loader from '../components/Loader';

export default function AboutScreen() {
    const [aboutData, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(`${API_URL}${ABOUT_ENDPOINT}`);
            if (!response.ok) {
              throw new Error('Request failed');
            }
            const jsonData = await response.json();
            setData(jsonData);
            setLoading(false);
          } catch (error) {
            setError(error.message);
            setLoading(false);
          }
        };
    
        fetchData();
      }, []);
    
      if (loading) {
        return <div className="container p-t-70 p-b-70 text-center"><Loader/></div>;
      }
    
      if (error) {
        return <div className="container p-t-70 p-b-70 text-center">Error: {error}</div>;
      }
  return (
    <div>
        <div>
            
            <section className="bg-img1 txt-center p-lr-15 p-tb-92" style={{backgroundImage: 'url("static/images/bg-01.jpg")'}}>
                <h2 className="ltext-105 cl0 txt-center text-dark">
                    About
                </h2>
            </section>	
            {/* Content page */}
            <section className="bg0 p-t-75 p-b-30">
                <div className="container">
                {aboutData.map((data) => (
                    <div key={data.id} className="row p-b-40">
                        <div className="col-md-12 col-lg-12">
                            <div className="p-t-7 p-r-0-md">
                                <h3 className="mtext-111 cl2 p-b-16 text-center">
                                {data.aboutTitle}
                                </h3>
                                <p className="stext-113 cl6 p-b-26">
                                {data.aboutDesc}
                                </p>
                            </div>
                        </div>
                        
                    </div>
                ))}
                </div>
            </section>
            <AboutTeam/>
        </div>
    </div>
  )
}

