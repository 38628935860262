/* COMPONENTS */
import React, { useEffect } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/ProductScreen";
import CartScreen from "./screens/CartScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ProfileScreen from "./screens/ProfileScreen";
import ShippingScreen from "./screens/ShippingScreen";
import PaymentScreen from "./screens/PaymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import UserListScreen from "./screens/UserListScreen";
import UserEditScreen from "./screens/UserEditScreen";
import ProductListScreen from "./screens/ProductListScreen";
import ProductEditScreen from "./screens/ProductEditScreen";
import OrderListScreen from "./screens/OrderListScreen";
import ShopScreen from "./screens/ShopScreen";
import AboutScreen from "./screens/AboutScreen";

/* REACT ROUTER */
import { HashRouter as Router, Route, useLocation  } from "react-router-dom";
import ContactScreen from "./screens/ContactScreen";
import TermScreen from "./screens/TermScreen";
import ReturnScreen from "./screens/ReturnScreen";
import PrivacyScreen from "./screens/PrivacyScreen";
import FaqScreen from "./screens/FaqScreen";
import DemoCard from "./screens/DemoCard";
// Google Analytics 
import ReactGA from 'react-ga';

// Initialize ReactGA with your tracking ID
ReactGA.initialize('UA-281174721-1');


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <div>
        <main>
          <Route exact path="/" component={HomeScreen} />
          <Route exact path="/shop" component={ShopScreen} />
          <Route exact path="/about" component={AboutScreen} />
          <Route exact path="/contact" component={ContactScreen} />

          <Route path="/login" component={LoginScreen} />
          <Route path="/register" component={RegisterScreen} />

          <Route path="/profile" component={ProfileScreen} />

          <Route path="/shipping" component={ShippingScreen} />
          <Route path="/payment" component={PaymentScreen} />
          <Route path="/placeorder" component={PlaceOrderScreen} />

          <Route path="/order/:id" component={OrderScreen} />

          <Route path="/product/:id" component={ProductScreen} />

          <Route path="/cart/:id?" component={CartScreen} />

          <Route path="/admin/userlist" component={UserListScreen} />
          <Route path="/admin/user/:id/edit" component={UserEditScreen} />
          <Route path="/admin/product/:id/edit" component={ProductEditScreen} />
          <Route path="/admin/productlist" component={ProductListScreen} />
          <Route path="/admin/orderlist" component={OrderListScreen} />

          <Route path="/terms" component={TermScreen} />
          <Route path="/return" component={ReturnScreen} />
          <Route path="/privacy" component={PrivacyScreen} />
          <Route path="/faq" component={FaqScreen} />
          <Route path="/DemoCard" component={DemoCard} />
        </main>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
