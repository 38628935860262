import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

export const WhyChoose = () => {
  return (
    <div>
        {/* WHY CHOOSE US  */}
        <section className="bg0 p-t-75 p-b-23">
        <div className="container">
            <div className="row">
            <div className="order-md-1 col-11 col-md-5 col-lg-4  p-b-30">
                <div>
                <div className="hov-img0">
                    <img src="static/images/chosecard.jpg" alt="IMG" />
                </div>
                </div>
            </div>
            <div className="order-md-2 col-md-7 col-lg-8 p-b-30">
                <div className="p-t-7 p-l-85 p-l-15-lg p-l-0-md">
                <h3 className="mtext-111 cl2 p-b-16">
                    WHY CHOOSE US
                </h3>
                <p className="stext-113 cl6 p-b-26">
                When it comes to making a lasting impression in the digital age, PrefaceBD.com stands out as a premier NFC business card company. With its innovative technology and exceptional service, Preface offers a compelling reason to choose their services. 
                </p><div className="bor16 p-l-29 p-b-9 m-t-22">
                    <p className="stext-114 cl6 p-r-40 p-b-11">
                    PrefaceBD.com understands the evolving needs of professionals and entrepreneurs who seek convenient and efficient networking solutions. By harnessing the power of NFC (Near Field Communication) technology, Preface transforms traditional business cards into interactive digital assets. With a simple tap of a smartphone, recipients can instantly access your contact information, social media profiles, and even personalized messages or promotional content. What sets Preface apart is its commitment to quality and customization. Each business card is meticulously designed and crafted to reflect your unique brand identity. With a wide range of templates, colors, fonts, and layouts to choose from, you can create a visually striking and memorable business card that leaves a lasting impression on clients and colleagues alike.
                    </p>
                    <p />
                    <span className="stext-111 cl8">
                    - PREFACE 
                    </span>
                    <div className="p-t-75">
                    <Link to='/DemoCard'>
                    <button className='stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 '>VIEW DEMO CARD</button>
                    </Link>

                    </div>
                </div>
               
                </div>
            </div>
            
            </div>
        </div>
        </section>

    </div>
  )
}
