import React from 'react'

export const HowWorks = () => {
  return (
    <div>
        {/* HOW IT WORKS  */}
        <section className="bg0 p-t-23 p-b-23">
        <div className="container">
            <div className="order-md-2  p-b-30">
            <div className="p-t-7 p-l-15-lg p-l-0-md">
                <h3 className="mtext-111 cl2 text-center">
                HOW PREFACE CARD WORKS
                </h3>
                <h5 className="cl2 text-center pt-2">This steps are included from order to production</h5>
                <div className=" m-t-23 p-t-43 p-b-40">
                {/* Tab01 */}
                <div className="tab01">
                    {/* Nav tabs */}
                    <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item p-b-10">
                        <a className="nav-link active text-center" data-toggle="tab" href="#step1" role="tab">
                            <p className='font-weight-bold'>STEP 1</p> 
                            <p>Design</p> 
                        </a>
                    </li>
                    <li className="nav-item p-b-10">
                        <a className="nav-link text-center" data-toggle="tab" href="#step2" role="tab">
                        <p className='font-weight-bold'>STEP 2</p> 
                        <p>Order</p> 
                        </a>
                    </li>
                    <li className="nav-item p-b-10">
                        <a className="nav-link text-center" data-toggle="tab" href="#step3" role="tab">
                        <p className='font-weight-bold'>STEP 3</p> 
                        <p>Print</p> 
                        </a>
                    </li>
                    <li className="nav-item p-b-10">
                        <a className="nav-link text-center" data-toggle="tab" href="#step4" role="tab">
                        <p className='font-weight-bold'>STEP 4</p> 
                        <p>NFC enable</p> 
                        </a>
                    </li>
                    <li className="nav-item p-b-10">
                        <a className="nav-link text-center" data-toggle="tab" href="#step5" role="tab">
                        <p className='font-weight-bold'>STEP 5</p> 
                        <p>Login & Edit</p> 
                        </a>
                    </li>
                    <li className="nav-item p-b-10">
                        <a className="nav-link text-center" data-toggle="tab" href="#step6" role="tab">
                        <p className='font-weight-bold'>STEP 6</p> 
                        <p>Share</p>
                            </a>
                    </li>
                   
                    </ul>
                    {/* Tab panes */}
                    <div className="tab-content p-t-43">
                    {/* - */}
                    <div className="tab-pane fade show  p-5 active" id="step1" role="tabpanel">
                       <div className="how-pos2 p-lr-15-md">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3 col-sm-12"></div>
                                <div className="col-md-6 col-sm-12 card text-center p-4" style={{boxShadow:'20px 20px 50px grey'}}>
                                    <h4 className='text-uppercase text-center mtext-111'>Design</h4>
                                    <p className='text-uppercase text-center'>Choose your design</p>
                                    <p className="stext-102 cl6 text-center mt-4">
                                        Select your design or if you have any custom design than provide your logo and other details in our email
                                    </p>
                                </div>
                                <div className="col-md-3 col-sm-12"></div>
                            </div>
                        </div>
                        </div> 
                    </div>
                    {/* - */}
                    <div className="tab-pane fade p-5" id="step2" role="tabpanel">
                        <div className="how-pos2 p-lr-15-md">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-3 col-sm-12"></div>
                                    <div className="col-md-6 col-sm-12 card text-center p-4" style={{boxShadow:'20px 20px 50px grey'}}>
                                        <h4 className='text-uppercase text-center mtext-111'>order</h4>
                                        <p className='text-uppercase text-center'>order your card from prefacbd.com</p>
                                        <p className="stext-102 cl6 text-center mt-4">
                                        Select a card and order your card from prefacbd.com. Complete the order process
                                        </p>
                                    </div>
                                    <div className="col-md-3 col-sm-12"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* - */}
                    <div className="tab-pane fade p-5" id="step3" role="tabpanel">
                        <div className="how-pos2 p-lr-15-md">
                            <div className="container">
                                <div className="row">
                                        <div className="col-md-3 col-sm-12"></div>
                                        <div className="col-md-6 col-sm-12 card text-center p-4" style={{boxShadow:'20px 20px 50px grey'}}>
                                            <h4 className='text-uppercase text-center mtext-111'>Print & Ship </h4>
                                            <p className='text-uppercase text-center'>Printing the design</p>
                                            <p className="stext-102 cl6 text-center mt-4">
                                                After approval we will print the design, program the card and ship at your given address
                                            </p>
                                        </div>
                                        <div className="col-md-3 col-sm-12"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* - */}
                    <div className="tab-pane fade p-5" id="step4" role="tabpanel">
                        <div className="how-pos2 p-lr-15-md">
                            <div className="container">
                                <div className="row">
                                        <div className="col-md-3 col-sm-12"></div>
                                        <div className="col-md-6 col-sm-12 card text-center p-4" style={{boxShadow:'20px 20px 50px grey'}}>
                                        <h4 className='text-uppercase text-center mtext-111'>Enable NFC and Punch Card </h4>
                                        <p className='text-uppercase text-center'>enable nfc in your phone</p>
                                        <p className="stext-102 cl6 text-center mt-4">
                                            Ensure your NFC is turned on in your Phone while punching the card
                                        </p>
                                        </div>
                                        <div className="col-md-3 col-sm-12"></div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    {/* - */}
                    <div className="tab-pane fade p-5" id="step5" role="tabpanel">
                        <div className="how-pos2 p-lr-15-md">
                            <div className="container">
                                <div className="row">
                                        <div className="col-md-3 col-sm-12"></div>
                                        <div className="col-md-6 col-sm-12 card text-center p-4" style={{boxShadow:'20px 20px 50px grey'}}>
                                        <h4 className='text-uppercase text-center mtext-111'>Login & Edit Profile </h4>
                                        <p className='text-uppercase text-center'>login by giving Username and Password</p>
                                        <p className="stext-102 cl6 text-center mt-4 ">
                                            After all this steps now creating your profile is a piece of cake. just punch the card, you can see the profile than login for customize your profile as you need it
                                        </p>
                                        </div>
                                        <div className="col-md-3 col-sm-12"></div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    {/* - */}
                    <div className="tab-pane fade p-5" id="step6" role="tabpanel">
                        <div className="how-pos2 p-lr-15-md">
                        <div className="container">
                                <div className="row">
                                        <div className="col-md-3 col-sm-12"></div>
                                        <div className="col-md-6 col-sm-12 card text-center p-4" style={{boxShadow:'20px 20px 50px grey'}}>
                                        <h4 className='text-uppercase text-center mtext-111'>SHARE YOUR PROFILE</h4>
                                        <p className='text-uppercase text-center'>Simply Share your information with anyone</p>
                                        <p className="stext-102 cl6 text-center mt-4">
                                        Now your profile is ready, just tap the card in phone and share your profile
                                        </p>
                                        </div>
                                        <div className="col-md-3 col-sm-12"></div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>

    </div>
  )
}
