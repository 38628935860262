import React from 'react'

export default function DemoCard() {
  return (
    <div>
     <div>
            <section className="bg-img1 txt-center p-lr-15 p-tb-92" style={{backgroundImage: 'url("static/images/bg-01.jpg")'}}>
                <h2 className="ltext-105 cl0 txt-center text-dark">
                    DEMO CARD
                </h2>
            </section>	
            {/* Content page */}
            <section className="bg0 p-t-25 p-b-25">
                <div className="container text-center">
                  <div className='row'>
                    <div className='col-md-4'></div>
                    <div className='col-md-4'>
                    <img src="static/images/prefaceProfile.png" alt="profile-img" style={{maxWidth: '100%', height: 'auto'}} />
                    </div>
                    <div className='col-md-4'></div>
                  </div>
                </div>
            </section>
        </div>
    </div>
  )
}
