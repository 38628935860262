import React, { useState, useEffect } from "react";

/* REACT ROUTER */
import { Link } from "react-router-dom";

/* REACT BOOTSTRAP */
import { Row, Col, Button, Form } from "react-bootstrap";

/* COMPONENTS */
import Message from "../components/Message";
import Loader from "../components/Loader";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

/* ACTION CREATORS */
import { login } from "../actions/userActions";

function LoginScreen({ location, history }) {
  /* STATE */
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");

  const dispatch = useDispatch();

  /* SETTING UP REDIRECT */
  const redirect = location.search ? location.search.split("=")[1] : "/";

  /* PULLING A PART OF STATE FROM THE ACTUAL STATE IN THE REDUX STORE */
  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo, loading, error } = userLogin;

  /* REDIRECTING AN ALREADY LOGGED IN USER, AS WE DON'T WANT THEM TO SEE THE LOGIN PAGE */
  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    }
  }, [history, userInfo, redirect]);

  /* HANDLERS */

  const submitHandler = (e) => {
    e.preventDefault();

    /* FIRING OFF THE ACTION CREATORS USING DISPATCH FOR LOGIN */
    dispatch(login(email, password));
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };



  return (
    <>
     <div>
        <section className="bg0 p-t-104 p-b-116">
            <div className="container">
                <div className='row'>
                    <div className="col-md-3">
                    </div>
                    <div className="col-md-6">
                    {error && <Message variant="danger">{error}</Message>}
                    {loading && <Loader />}
                        <div className="bor10 p-lr-70 p-t-55 p-b-70 p-lr-15-lg w-full-md">
                            <form onSubmit={submitHandler}>
                                <h4 className="mtext-105 cl2 txt-center p-b-30">
                                LOG IN
                                </h4>
                                <div className="bor8 m-b-20 how-pos4-parent">
                                <input className="stext-111 cl2 plh3 size-116 p-l-20 p-r-30" type="email"
                                placeholder="Enter Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="bor8 mb-2">
                                <input className="stext-111 cl2 plh3 size-116 p-l-20 p-r-30" 
                                // type="password"
                                placeholder="Enter Password"
                                value={password}
                                type={showPassword ? 'text' : 'password'}
                                onChange={(e) => setpassword(e.target.value)} 
                                />
                                </div>

                                <div className="mt-0 m-b-30 text-right">
                                  <span style={{cursor:'pointer'}} onClick={handleTogglePassword}>
                                    {showPassword ? 'Hide' : 'Show'} Password
                                  </span>
                                </div>
                                
                                <button className="flex-c-m stext-101 cl0 size-121 bg3 bor1 hov-btn3 p-lr-15 trans-04 pointer" type="submit">
                                LOGIN
                                </button>
                                <Row className="py-3">
                                  <Col>
                                    New Customer ?{" "}
                                    <Link to={redirect ? `/register?redirect=${redirect}` : "/register"}>
                                      Register
                                    </Link>
                                  </Col>
                                </Row>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-3">
                    </div>
                </div>
            </div>
        </section>
    </div>
    
    {/* <FormContainer>
      <h1>Sign In</h1>

      {error && <Message variant="danger">{error}</Message>}
      {loading && <Loader />}

      <Form onSubmit={submitHandler}>
        <Form.Group controlId="email">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setpassword(e.target.value)}
          />
        </Form.Group>

        <Button type="submit" variant="primary" className="mt-3">
          Sign In
        </Button>
      </Form>

      <Row className="py-3">
        <Col>
          New Customer ?{" "}
          <Link to={redirect ? `/register?redirect=${redirect}` : "/register"}>
            Register
          </Link>
        </Col>
      </Row>
    </FormContainer> */}
    </>
  );
}

export default LoginScreen;
