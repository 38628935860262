import React, { useEffect, useState } from 'react';
import { API_URL, TEAM_ENDPOINT } from '../apiConfig';
import Loader from '../components/Loader';
import { Link } from 'react-router-dom';

export default function AboutTeam() {
    const [teamData, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(`${API_URL}${TEAM_ENDPOINT}`);
            if (!response.ok) {
              throw new Error('Request failed');
            }
            const jsonData = await response.json();
            setData(jsonData);
            setLoading(false);
          } catch (error) {
            setError(error.message);
            setLoading(false);
          }
        };
    
        fetchData();
      }, []);
    
      if (loading) {
        return <div className="container p-t-70 p-b-70 text-center"><Loader/></div>;
      }
    
      if (error) {
        return <div className="container p-t-70 p-b-70 text-center">Error: {error}</div>;
      }
  return (
    <>
        <div className='text-center text-uppercase p-b-15'>
          <h3 class="mtext-200 cl2  text-center font-weight-bold ">TEAM MEMBERS</h3>
          <p>Our Passionate team members</p>
        </div>
        <div className="container text-center p-b-120">
              <div className="row">
              {teamData.map((data) => (
                <div key={data._id} className="col-lg-3 mt-5">
                  <a href={data.teamImage} target="_blank">
                    <img className="rounded-circle" src={data.teamImage} alt="Generic placeholder image" width={140} height={140} style={{objectFit:"cover"}}/>
                  </a>
                  <h5 className='font-weight-bold mt-2 mb-2'>{data.teamName}</h5>
                  <p>{data.teamDesignation}</p>
                  <p>{data.teamDescription}</p>
                  <div className="p-t-10">
                    <Link to={data.teamfacebookURL} className="fs-18 cl7 hov-cl1 trans-04 m-r-16  text-dark" target="_blank">
                        <i className="fa fa-facebook" />
                    </Link>
                    <Link to={data.teaminstaURL} className="fs-18 cl7 hov-cl1 trans-04 m-r-16  text-dark" target="_blank">
                        <i className="fa fa-instagram" />
                    </Link>
                    <Link to={data.teamlinkdinURL} className="fs-18 cl7 hov-cl1 trans-04 m-r-16  text-dark" target="_blank">
                        <i className="fa fa-linkedin" />
                    </Link>
                  </div>
                </div>
              ))}
              </div>
            </div>
    </>
  )
}
