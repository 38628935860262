import React from "react";
import $ from 'jquery';
import { Link } from 'react-router-dom';

/* REACT BOOTSTRAP */
import { NavDropdown } from "react-bootstrap";

/* REACT ROUTER BOOTSTRAP */
import { LinkContainer } from "react-router-bootstrap";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

/* ACTION CREATORS */
import { logout } from "../actions/userActions";

/* COMPONENTS */
import SearchBox from "./SearchBox";


function Header() {
  /* PULLING A PART OF STATE FROM THE ACTUAL STATE IN THE REDUX STORE */
  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  /* HANDLER */
  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logout());
  };
  // jQuery 
  $(document).ready(function() {
    var headerDesktop = $('.container-menu-desktop');
    var wrapMenu = $('.wrap-menu-desktop');

    if($('.top-bar').length > 0) {
        var posWrapHeader = $('.top-bar').height();
    }
    else {
        var posWrapHeader = 0;
    }
    

    if($(window).scrollTop() > posWrapHeader) {
        $(headerDesktop).addClass('fix-menu-desktop');
        $(wrapMenu).css('top',0); 
    }  
    else {
        $(headerDesktop).removeClass('fix-menu-desktop');
        $(wrapMenu).css('top',posWrapHeader - $(this).scrollTop()); 
    }

    $(window).on('scroll',function(){
        if($(this).scrollTop() > posWrapHeader) {
            $(headerDesktop).addClass('fix-menu-desktop');
            $(wrapMenu).css('top',0); 
        }  
        else {
            $(headerDesktop).removeClass('fix-menu-desktop');
            $(wrapMenu).css('top',posWrapHeader - $(this).scrollTop()); 
        } 
    });

    $('.btn-show-menu-mobile').on('click', function(){
      $(this).toggleClass('is-active');
      $('.menu-mobile').slideToggle();
  });

  var arrowMainMenu = $('.arrow-main-menu-m');

  for(var i=0; i<arrowMainMenu.length; i++){
      $(arrowMainMenu[i]).on('click', function(){
          $(this).parent().find('.sub-menu-m').slideToggle();
          $(this).toggleClass('turn-arrow-main-menu-m');
      })
  }

  $(window).resize(function(){
      if($(window).width() >= 992){
          if($('.menu-mobile').css('display') == 'block') {
              $('.menu-mobile').css('display','none');
              $('.btn-show-menu-mobile').toggleClass('is-active');
          }

          $('.sub-menu-m').each(function(){
              if($(this).css('display') == 'block') { console.log('hello');
                  $(this).css('display','none');
                  $(arrowMainMenu).removeClass('turn-arrow-main-menu-m');
              }
          });
              
      }
  });
    // SEARCH MODAL 
    $('.js-show-modal-search').on('click', function(){
        $('.modal-search-header').addClass('show-modal-search');
        $(this).css('opacity','0');
    });

    $('.js-hide-modal-search').on('click', function(){
        $('.modal-search-header').removeClass('show-modal-search');
        $('.js-show-modal-search').css('opacity','1');
    });

    $('.container-search-header').on('click', function(e){
        e.stopPropagation();
    });
    // SEARCH MODAL FINISH 

    // CART 
    $('.js-show-cart').on('click',function(){
        $('.js-panel-cart').addClass('show-header-cart');
    });

    $('.js-hide-cart').on('click',function(){
        $('.js-panel-cart').removeClass('show-header-cart');
    });

    $('.js-show-sidebar').on('click',function(){
        $('.js-sidebar').addClass('show-sidebar');
    });

    $('.js-hide-sidebar').on('click',function(){
        $('.js-sidebar').removeClass('show-sidebar');
    });
    // CART FINISH 
  });

  return (
    <header>
      <div className="container-menu-desktop">
        {/* Topbar */}
        <div className="top-bar">
                  <div className="content-topbar flex-sb-m h-full container">
                      <div className="left-top-bar">
                      Free shipping for standard order over 2000 TK
                      </div>
                      <div className="right-top-bar flex-w h-full ">
                        {userInfo ? (
                          <>
                            <Link to="/profile" className="flex-c-m trans-04 p-lr-25">
                              {userInfo.name}
                            </Link>
                            
                            <Link onClick={logoutHandler} className="flex-c-m trans-04 p-lr-25">
                                Logout
                            </Link>
                          </>
                        ) : (
                          <Link to="/login" className="flex-c-m trans-04 p-lr-25">
                            Login
                          </Link>
                        )}

                        {userInfo && userInfo.isAdmin && (
                          <NavDropdown title="Admin" id="adminmenu">
                            <LinkContainer to="/admin/userlist">
                              <NavDropdown.Item>Users</NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to="/admin/productlist">
                              <NavDropdown.Item>Products</NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to="/admin/orderlist">
                              <NavDropdown.Item>Orders</NavDropdown.Item>
                            </LinkContainer>
                          </NavDropdown>
                        )}
                      </div>
                  </div>
        </div>
        <div className="wrap-menu-desktop">
          <nav className="limiter-menu-desktop container">
                    {/* Logo desktop */}		
                    <Link to={'/'} className="logo">
                    <img src="static/images/icons/prb.png" alt="IMG-LOGO" />
                    </Link>
                    {/* Menu desktop */}
                    <div className="menu-desktop">
                      <ul className="main-menu">
                          <li className="active-menu">
                          <Link to={"/"}>Home</Link>
                          </li>
                          <li className="label1" data-label1="new">
                          <Link to={"/shop"}>Shop</Link>
                          </li>
                          {/* <li>
                          <Link to={"/blog"}>Blog</Link>
                          </li> */}
                          <li>
                          <Link to={"/about"}>About</Link>
                          </li>
                          <li>
                          <Link to={'/contact'}>Contact</Link>
                          </li>
                      </ul>
                    </div>	
                    {/* Icon header */}
                    <div className="wrap-icon-header flex-w flex-r-m">
                    <div className="icon-header-item cl2 hov-cl1 trans-04 p-l-22 p-r-11 js-show-modal-search">
                        <i className="zmdi zmdi-search" />
                    </div>
                    <div className="icon-header-item cl2 hov-cl1 trans-04 p-l-22 p-r-11  js-show-cart" 
                    // data-notify={2}
                    >
                        <Link to="/cart" className="text-dark">
                          <i className="zmdi zmdi-shopping-cart" />
                        </Link>
                    </div>
                    </div>
          </nav>
        </div>	
      </div>

      {/* Header Mobile */}
      <div>
            <div className="wrap-header-mobile">
                {/* Logo moblie */}		
                <div className="logo-mobile">
                <Link to={'/'}><img src="static/images/icons/prb.png" alt="IMG-LOGO" /></Link>
                </div>
                {/* Icon header */}
                <div className="wrap-icon-header flex-w flex-r-m m-r-15">
                <div className="icon-header-item cl2 hov-cl1 trans-04 p-r-11 js-show-modal-search">
                    <i className="zmdi zmdi-search" />
                </div>
                <div className="icon-header-item cl2 hov-cl1 trans-04 p-r-11 p-l-10 js-show-cart">
                    <Link to="/cart" className="text-dark">
                        <i className="zmdi zmdi-shopping-cart" />
                    </Link>
                </div>
                
                </div>
                {/* Button show menu */}
                <div className="btn-show-menu-mobile hamburger hamburger--squeeze">
                  <span className="hamburger-box">
                      <span className="hamburger-inner" />
                  </span>
                </div>
            </div>
            {/* Menu Mobile */}
            <div className="menu-mobile">
                <ul className="topbar-mobile">
                <li>
                    <div className="left-top-bar">
                    Free shipping for standard order over Tk 1200
                    </div>
                </li>
                <li>
                    <div className="right-top-bar flex-w h-full">
                    {userInfo ? (
                        <>
                            <Link to="/profile" className="flex-c-m p-lr-10 trans-04">
                            {userInfo.name}
                            </Link>
                            <Link onClick={logoutHandler} className="flex-c-m p-lr-10 trans-04">
                              Logout
                            </Link>
                        </>
                        ) : (
                            <Link to="/login" className="flex-c-m trans-04 p-lr-25">
                            Login
                            </Link>
                        )}
                        {userInfo && userInfo.isAdmin && (
                            <NavDropdown title="Admin" id="adminmenu">
                                <LinkContainer to="/admin/userlist">
                                <NavDropdown.Item>Users</NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to="/admin/productlist">
                                <NavDropdown.Item>Products</NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to="/admin/orderlist">
                                <NavDropdown.Item>Orders</NavDropdown.Item>
                                </LinkContainer>
                            </NavDropdown>
                            )}
                    </div>
                </li>
                </ul>
                <ul className="main-menu-m" style={{backgroundColor:'#8f1c1e'}}>
                <li>
                    <Link to={'/'}>Home</Link>
                </li>
                <li className="label1 rs1" data-label1="new">
                    <Link to={"/shop"}>Shop</Link>
                </li>
                {/* <li>
                    <Link to="blog.html">Blog</Link>
                </li> */}
                <li>
                    <Link to={"/about"}>About</Link>
                </li>
                <li>
                    <Link to={'/contact'}>Contact</Link>
                </li>
                </ul>
            </div>
        </div>
      <SearchBox/>
    </header>
  );
}

export default Header;
