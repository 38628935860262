import React, { useState } from "react";

/* REACT BOOTSTRAP */
import { Button, Form } from "react-bootstrap";

/* COMPONENTS */
import FormContainer from "../components/FormContainer";
import CheckoutSteps from "../components/CheckoutSteps";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

/* ACTION CREATORS */
import { saveShippingAddress } from "../actions/cartActions";

function ShippingScreen({ history }) {
  // PULLING OUT SHIPPING ADDRESS FROM CART
  const cart = useSelector((state) => state.cart);

  const { shippingAddress } = cart;

  // STATE
  const [Contact, setContact] = useState(shippingAddress.Contact);
  const [address, setAddress] = useState(shippingAddress.address);
  const [city, setCity] = useState(shippingAddress.city);
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode);
  const [country, setCountry] = useState(shippingAddress.country);
  const [cardName, setCardName] = useState(shippingAddress.cardName);
  const [cardDetails, setCardDetails] = useState(shippingAddress.cardDetails);

  const dispatch = useDispatch();

  // HANDLERS
  const submitHandler = (e) => {
    e.preventDefault();

    /* FIRING OFF THE ACTION CREATORS USING DISPATCH TO SAVE ADDRESS */
    dispatch(
      saveShippingAddress({
        Contact,
        address,
        city,
        postalCode,
        country,
        cardName,
        cardDetails,
      })
    );

    // PUSHING USER TO PAYMENTS PAGE AFTER SAVING ADDRESS
    history.push("./payment");
  };

  return (
    <>
      <div className="container p-t-75 p-b-50">
        <div className="bread-crumb flex-w p-l-25 p-r-15 p-t-30 p-lr-0-lg">
          <FormContainer>
            <CheckoutSteps step1 step2 />
            <h3 className="font-weight-bold text-center p-b-30">SHIPPING DETAILS</h3>
            <Form onSubmit={submitHandler}>
                  <Form.Group controlId="Contact">
                    <Form.Label>Contact Number</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter Number"
                      value={Contact ? Contact : ""}
                      onChange={(e) => setContact(e.target.value)}
                    />
                  </Form.Group>

              <div className="row">
                <div className="col">
                  <Form.Group controlId="city">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter City"
                      value={city ? city : ""}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group controlId="country">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter Country"
                      value={country ? country : ""}
                      onChange={(e) => setCountry(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </div>
              
              <Form.Group controlId="address">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Address"
                  value={address ? address : ""}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="postalCode">
                <Form.Label>Postal Code (Optional)</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Postal Code"
                  value={postalCode ? postalCode : ""}
                  onChange={(e) => setPostalCode(e.target.value)}
                />
              </Form.Group>

              <h4 className="text-uppercase font-weight-bold p-t-20 p-b-20">CARD DETAILS</h4>
              <Form.Group controlId="cardName">
                <Form.Label>Card Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter Card Name"
                  value={cardName ? cardName : ""}
                  onChange={(e) => setCardName(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="cardDetails">
                <Form.Label>Your Designation</Form.Label>
                <Form.Control
                  required
                  as="textarea" rows={3}
                  type="text"
                  placeholder="Enter Your Designation"
                  value={cardDetails ? cardDetails : ""}
                  onChange={(e) => setCardDetails(e.target.value)}
                />
              </Form.Group>

              

              <Button className="my-3 btn-dark" type="submit" >
                Continue
              </Button>
            </Form>
          </FormContainer>
        </div>
      </div>
    </>
  );
}

export default ShippingScreen;
