import React from "react";

/* REACT-BOOTSTRAP */
import {Card} from "react-bootstrap";

/* REACT ROUTER */
import {Link} from "react-router-dom";

/* COMPONENTS */
import Rating from "./Rating";

function Product({product}) {
    return (
        <>
            <div className="block2-pic hov-img0">
                <img src={
                        product.image
                    }
                    alt="IMG-PRODUCT"/>
                <Link to={
                        `/product/${
                            product._id
                        }`
                    }
                    className="block2-btn flex-c-m stext-103 cl2 size-102 bg0 bor2 hov-btn1 p-lr-15 trans-04 js-show-modal1">
                    Quick View
                </Link>
            </div>
            <div className="block2-txt flex-w flex-t p-t-14 text-center">
                <div className="block2-txt-child1 flex-col-l ">
                    <Link to={
                            `/product/${
                                product._id
                            }`
                        }
                        className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6">
                        {
                        product.name
                    } </Link>
                    <span className="stext-105 cl3">
                        TK {
                        product.price
                    } </span>
                </div>
                <div className="block2-txt-child2 flex-r p-t-3"></div>
            </div>
    </>
    );
}

export default Product;
