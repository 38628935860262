import React, { useEffect } from "react";

/* REACT ROUTER */
import { Link } from "react-router-dom";

/* REACT BOOTSTRAP */
import { Button, Row, Col, ListGroup, Image, Card } from "react-bootstrap";

/* COMPONENTS */
import CheckoutSteps from "../components/CheckoutSteps";
import Message from "../components/Message";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

/* ACTION CREATORS */
import { createOrder } from "../actions/orderActions";

/* ACTION TYPES */
import { ORDER_CREATE_RESET } from "../constants/orderConstants";

function PlaceOrderScreen({ history }) {
  const dispatch = useDispatch();

  /* PULLING A PART OF STATE FROM THE ACTUAL STATE IN THE REDUX STORE */
  const orderCrate = useSelector((state) => state.orderCreate);

  const { order, error, success } = orderCrate;

  const cart = useSelector((state) => state.cart);

  // PRICE CALCULATIONS, WE ARE SETTING AN ATTRIBUTE TO OUR CART OBJECT BUT IT WON'T UPDATE OUR STATE, IT'S JUST FOR THIS PAGE
  cart.itemsPrice = cart.cartItems
    .reduce((acc, item) => acc + item.price * item.qty, 0)
    .toFixed(2);

  cart.shippingPrice = (cart.shippingAddress.city == 'Chattogram' ? 70 : 120).toFixed(2);
  // cart.shippingPrice = (cart.itemsPrice > 20000 ? 0 : 10).toFixed(2);

  cart.taxPrice = Number(0 * cart.itemsPrice).toFixed(2);

  cart.totalPrice = (
    Number(cart.itemsPrice) +
    Number(cart.shippingPrice) +
    Number(cart.taxPrice)
  ).toFixed(2);

  // REDIRECT
  if (!cart.paymentMethod) {
    history.push("/payment");
  }

  /* IF ORDER SUCCESSFULL AND WE HAVE ORDER ID, SEND USER TO USERS ACCOUNT TO VIEW THE ORDER */
  useEffect(() => {
    if (success) {
      history.push(`/order/${order._id}`);

      // RESET STATE
      dispatch({
        type: ORDER_CREATE_RESET,
      });
    }
    // eslint-disable-next-line
  }, [success, history]);

  // HANDLERS
  const placeorder = () => {
    dispatch(
      createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        paymentMethod: cart.paymentMethod,
        itemsPrice: cart.itemsPrice,
        shippingPrice: cart.shippingPrice,
        taxPrice: cart.taxPrice,
        totalPrice: cart.totalPrice,
      })
    );
  };

  return (
      <div className="container p-t-75 p-b-75">
        <div className="bread-crumb flex-w p-l-25 p-r-15 p-t-30 p-lr-0-lg">
          <CheckoutSteps step1 step2 step3 step4 />
            <Row>
              <Col md={8}>
                <Card>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <h5 className="text-uppercase p-b-20">Shipping</h5>
                      <p>
                        <strong>Contact: </strong>
                        {cart.shippingAddress.Contact}
                      </p>
                      <p>
                        <strong>Shipping Address: </strong>
                        {cart.shippingAddress.address}, {cart.shippingAddress.city},{" "}
                        {cart.shippingAddress.postalCode},{" "}
                        {cart.shippingAddress.country}
                      </p>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <h5 className="text-uppercase p-b-20 p-t-20">Payment</h5>
                      <p>
                        <strong>Payment Method: </strong>
                        {cart.paymentMethod}
                      </p>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <h5 className="text-uppercase p-b-20 p-t-20">Card Information</h5>
                      <p>
                        <strong>Card Name: </strong>
                        {cart.shippingAddress.cardName}
                      </p>
                      <p>
                        <strong>Card Details: </strong>
                        {cart.shippingAddress.cardDetails}
                      </p>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <h5 className="text-uppercase p-b-20 p-t-20">Order Items</h5>

                      {cart.cartItems.length === 0 ? (
                        <Message variant="info">Your cart is empty</Message>
                      ) : (
                        <ListGroup variant="flush">
                          {cart.cartItems.map((item, index) => (
                            <ListGroup.Item key={index}>
                              <Row>
                                <Col md={1}>
                                  <Image
                                    src={item.image}
                                    alt={item.name}
                                    fluid
                                    rounded
                                  />
                                </Col>

                                <Col>
                                  <Link className='text-dark' to={`/product${item.product}`}>
                                    {item.name}
                                  </Link>
                                </Col>

                                <Col md={4}>
                                  {item.qty} X Tk {item.price} = Tk 
                                  {(item.qty * item.price).toFixed(2)}
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      )}
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              </Col>

              <Col md={4}>
                <Card>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <h5 className="text-uppercase" >Order Summary</h5>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col className="font-weight-bold">Items:</Col>

                        <Col>Tk {cart.itemsPrice}</Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col className="font-weight-bold">Shipping:</Col>

                        <Col>Tk {cart.shippingPrice}</Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col className="font-weight-bold">Tax:</Col>

                        <Col>Tk {cart.taxPrice}</Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col className="font-weight-bold">Total:</Col>

                        <Col>Tk {cart.totalPrice}</Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      {error && <Message variant="danger">{error}</Message>}
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Button
                        type="button"
                        className="w-100 stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 "
                        disabled={cart.cartItems === 0}
                        onClick={placeorder}
                      >
                        Place Order
                      </Button>
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              </Col>
            </Row>
        </div>
      </div>
  );
}

export default PlaceOrderScreen;
