import React from "react";

/* REACT BOOTSTRAP */
import { Nav } from "react-bootstrap";

/* REACT ROUTER BOOTSTRAP */
import { LinkContainer } from "react-router-bootstrap";

function CheckoutSteps({ step1, step2, step3, step4 }) {
  return (
    <Nav className="justify-content-center mb-4">
      <Nav.Item>
        {step1 ? (
          <LinkContainer to="/login">
            <Nav.Link className="font-weight-bold text-success text-uppercase">Login</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link className="font-weight-bold text-uppercase" disabled>Login</Nav.Link>
        )}
      </Nav.Item>

      <Nav.Item>
        {step2 ? (
          <LinkContainer to="/shipping">
            <Nav.Link className="font-weight-bold text-success text-uppercase">Shipping</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link className="font-weight-bold text-uppercase" disabled>Shipping</Nav.Link>
        )}
      </Nav.Item>

      <Nav.Item>
        {step3 ? (
          <LinkContainer to="/payment">
            <Nav.Link className="font-weight-bold text-success text-uppercase">Payment</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link className="font-weight-bold text-uppercase" disabled>Payment</Nav.Link>
        )}
      </Nav.Item>

      <Nav.Item>
        {step4 ? (
          <LinkContainer to="/placeorder">
            <Nav.Link className="font-weight-bold text-success text-uppercase">Place Order</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link className="font-weight-bold text-uppercase" disabled>Place Order</Nav.Link>
        )}
      </Nav.Item>
    </Nav>
  );
}

export default CheckoutSteps;
