import React from "react";

function Rating({ value, text, color }) {
  return (
<>
    <div className="rating">
      <span>
        <i
          style={{ color }}
          className={
            value >= 1
              ? "zmdi zmdi-star"
              : value >= 0.5
              ? "zmdi zmdi-star-half"
              : "zmdi zmdi-star"
          }
        ></i>
      </span>

      <span>
        <i
          style={{ color }}
          className={
            value >= 2
              ? "zmdi zmdi-star"
              : value >= 1.5
              ? "zmdi zmdi-star-half"
              : "zmdi zmdi-star"
          }
        ></i>
      </span>

      <span>
        <i
          style={{ color }}
          className={
            value >= 3
              ? "zmdi zmdi-star"
              : value >= 2.5
              ? "zmdi zmdi-star-half"
              : "zmdi zmdi-star"
          }
        ></i>
      </span>

      <span>
        <i
          style={{ color }}
          className={
            value >= 4
              ? "zmdi zmdi-star"
              : value >= 3.5
              ? "zmdi zmdi-star-half"
              : "zmdi zmdi-star"
          }
        ></i>
      </span>

      <span>
        <i
          style={{ color }}
          className={
            value >= 5
              ? "zmdi zmdi-star"
              : value >= 4.5
              ? "zmdi zmdi-star-half"
              : "zmdi zmdi-star"
          }
        ></i>
      </span>

      <span>{text ? text : ""}</span>
    </div>
    </>
  );
}

export default Rating;
