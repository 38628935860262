import React, { useEffect } from "react";

/* REACT ROUTER */
import { Link } from "react-router-dom";

/* COMPONENTS */
import Message from "../components/Message";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

/* ACTION CREATORS */
import { addToCart, removeFromCart } from "../actions/cartActions";

function CartScreen({ match, location, history }) {
  /* GETTING DATA FROM URL IF PRESENT */
  const productId = match.params.id;

  const qty = location.search ? Number(location.search.split("=")[1]) : 1;
  //qty: '?qty=3' -> ['?qty',3] -> 3

  /* FIRING OFF DISPATCH, BUT ONLY IF WE HAVE A PRODUCT ID & QUANTITY */
  const dispatch = useDispatch();

  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, qty));
    }
  }, [dispatch, productId, qty]);

  /* PULLING A PART OF STATE FROM THE ACTUAL STATE IN THE REDUX STORE */
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  /* HANDLERS */

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  const checkoutHandler = () => {
    history.push("/login?redirect=shipping");
  };

  return (
    <>
    <div>
    <section className="bg-img1 txt-center p-lr-15 p-tb-92" style={{backgroundImage: 'url("static/images/bg-01.jpg")'}}>
                <h2 className="ltext-105 cl0 txt-center text-dark">
                    YOUR CART
                </h2>
            </section>
            {/* breadcrumb */}
            <div className="container p-t-75">
                <div className="bread-crumb flex-w p-l-25 p-r-15 p-t-30 p-lr-0-lg">
                <a href="index.html" className="stext-109 cl8 hov-cl1 trans-04">
                    Home
                    <i className="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true" />
                </a>
                <span className="stext-109 cl4">
                    Cart
                </span>
                </div>
            </div>
            {/* Shoping Cart */}
            <div className="bg0 p-t-75 p-b-85">
                <div className="container">
                {cartItems.length === 0 ? (
                  <Message variant="info">
                    Your cart is empty. <Link to="/">Go Back</Link>
                  </Message>
                ) : (
                  <div className="row">
                      <div className="col-lg-10 col-xl-7 m-lr-auto m-b-50">
                      <div className="m-l-25 m-r--38 m-lr-0-xl">
                          <div className="wrap-table-shopping-cart">
                          <table className="table-shopping-cart table-responsive table-sm table-bordered">
                              <tbody><tr className="table_head">
                                  <th className="text-center"></th>
                                  <th className="column-2 text-center">Product Name</th>
                                  <th className="column-3 text-center">Price</th>
                                  <th className="column-4 text-center">Quantity</th>
                                  <th className=" text-center">Remove</th>
                              </tr>

                              {cartItems.map((item) => (
                                <tr className="table_row text-center" key={item.product}>
                                    <td >
                                    <div className="how-itemcart1">
                                        <img src={item.image} alt={item.name}/>
                                    </div>
                                    </td>
                                    <td className="column-2"><Link className="text-dark" to={`/product/${item.product}`}>{item.name}</Link></td>
                                    <td className="column-3">Tk {item.price}</td>
                                    <td className="column-4">
                                      <div className="flex-w m-l-auto m-r-0">
                                      
                                        <select className="form-control p-2" as="select"
                                        value={item.qty}
                                        onChange={(e) =>
                                          dispatch(
                                            addToCart(item.product, Number(e.target.value))
                                          )
                                        }>
                                        {[...Array(item.countInStock).keys()].map((x) => (
                                            <option key={x + 1} value={x + 1}>
                                              {x + 1}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <button className="text-danger" type="button"
                                        variant="light"
                                        onClick={() => removeFromCartHandler(item.product)}>
                                          <i className="fa fa-trash"></i>
                                      </button>
                                    </td>

                                </tr>
                              ))}
                            
                              </tbody></table>
                          </div>
                          
                      </div>
                      </div>
                      <div className="col-sm-10 col-lg-7 col-xl-5 m-lr-auto m-b-50">
                      <div className="bor10 p-lr-40 p-t-30 p-b-40 m-l-63 m-r-40 m-lr-0-xl p-lr-15-sm">
                          <p className="mtext-109 cl2 p-b-30">
                          Cart Totals ({cartItems.reduce((acc, item) => acc + item.qty, 0)} Items)
                          </p>
                          <div className="flex-w flex-t bor12 p-b-13">
                            <div className="size-208">
                                <span className="stext-110 cl2">
                                <strong>Subtotal: </strong>
                                </span>
                            </div>
                            <div className="size-209">
                                <span className="mtext-110 cl2">
                                Tk {cartItems.reduce((acc, item) => acc + item.qty * item.price, 0)
                                  .toFixed(2)}
                                </span>
                            </div>
                          </div>
                          
                        
                          <button className="flex-c-m stext-101 cl0 size-116 bg3 bor14 hov-btn3 p-lr-15 trans-04 pointer mt-5" type="button"
                          disabled={cartItems.length === 0}
                          onClick={checkoutHandler}>
                          Proceed to Checkout
                          </button>
                      </div>
                      </div>
                  </div>
                )}
                </div>
            </div>
        </div>
    {/* <Row>
      <Col md={8}>
        <h1>Shopping Cart</h1>
        {cartItems.length === 0 ? (
          <Message variant="info">
            Your cart is empty. <Link to="/">Go Back</Link>
          </Message>
        ) : (
          <ListGroup variant="flush">
            {cartItems.map((item) => (
              <ListGroup.Item key={item.product}>
                <Row>
                  <Col md={2}>
                    <Image src={item.image} alt={item.name} fluid rounded />
                  </Col>

                  <Col m={3}>
                    <Link to={`/product/${item.product}`}>{item.name}</Link>
                  </Col>

                  <Col>₹{item.price}</Col>

                  <Col md={3}>
                    <Form.Control
                      as="select"
                      value={item.qty}
                      onChange={(e) =>
                        dispatch(
                          addToCart(item.product, Number(e.target.value))
                        )
                      }
                    >
                      {[...Array(item.countInStock).keys()].map((x) => (
                        <option key={x + 1} value={x + 1}>
                          {x + 1}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>

                  <Col md={1}>
                    <Button
                      type="button"
                      variant="light"
                      onClick={() => removeFromCartHandler(item.product)}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Col>

      <Col md={4}>
        <Card>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2>
                Subtotal ({cartItems.reduce((acc, item) => acc + item.qty, 0)})
                items
              </h2>
              ₹
              {cartItems
                .reduce((acc, item) => acc + item.qty * item.price, 0)
                .toFixed(2)}
            </ListGroup.Item>
          </ListGroup>

          <ListGroup.Item>
            <Button
              type="button"
              className="w-100"
              disabled={cartItems.length === 0}
              onClick={checkoutHandler}
            >
              Proceed To Checkout
            </Button>
          </ListGroup.Item>
        </Card>
      </Col>
    </Row> */}
    </>
  );
}

export default CartScreen;
